"use client"

import { skipToken } from "@reduxjs/toolkit/query"
import React, { useEffect } from "react"
import { Message, PerformancePlatformSubscription } from "types"
import { useGetMessagesQuery } from "../../api"

const token = (ppSubId): { ppSubId: string } | undefined => {
    if (ppSubId && ppSubId.length > 0) return { ppSubId }

    return undefined
}

export function useMessages(ppSubId: PerformancePlatformSubscription['id']): {
    messageCount: number
    messages: Message[]
    activeMessages: Message[]
    archivedMessages: Message[]
    loading: boolean
} {
    const { data, isLoading } = useGetMessagesQuery(token(ppSubId) ?? skipToken)

    const [activeMessages, setActiveMessages] = React.useState<Message[]>([])
    const [archivedMessages, setArchivedMessages] = React.useState<Message[]>([])

    useEffect(() => {
        if (data) {
            setActiveMessages(data.filter((m) => !m.isArchived))
            setArchivedMessages(data.filter((m) => m.isArchived))
        }
    }, [data])

    return {
        messageCount: data?.length ?? 0,
        messages: data ?? [],
        activeMessages,
        archivedMessages,
        loading: isLoading, // ? should we use isLoading or isFetching?  Currently, will only show loading on initial load, rather than on every query.
    }
}
