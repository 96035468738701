import { skipToken } from "@reduxjs/toolkit/query"
import { useGetBrewMenuDisplayByIdQuery } from "../../../api"

const token = (
    displayId: string,
    bmSubId: string,
):
    | {
        itemId: string
        parentId: string
    }
    | undefined => {
    if (displayId && bmSubId) {
        return {
            itemId: displayId,
            parentId: bmSubId,
        }
    }

    return undefined
}

export const useBrewMenuDisplayById = (displayId: string, bmSubId: string) => {

    const { data, isError, isFetching, isLoading, isSuccess } =
        useGetBrewMenuDisplayByIdQuery(token(displayId, bmSubId) ?? skipToken)

    return {
        display: data,
        isErrorBrewMenuDisplayById: isError,
        isFetchingBrewMenuDisplayById: isFetching,
        isLoadingBrewMenuDisplayById: isLoading,
        isSuccessBrewMenuDisplayById: isSuccess,
    }
}
