import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export enum TableFilterEnums {
    All = "all",
    Tap = "tap",
    Date = "date",
    Product = "product",
    BAStyle = "baStyle",
    BLXStyle = "blxStyle",
    ABV = "abv"
}

export interface ThroughputTableFilterState {
    activeThroughputTableFilter: TableFilterEnums
}

const initialState: ThroughputTableFilterState = {
    activeThroughputTableFilter: TableFilterEnums.All
}

const throughputTableFilterSlice = createSlice({
    name: "throughput-table-filter-state",
    initialState,
    reducers: {
        setActiveThroughputTableFilter: (
            state,
            action: PayloadAction<ThroughputTableFilterState>
        ) => {
            state.activeThroughputTableFilter =
                action.payload?.activeThroughputTableFilter ?? TableFilterEnums.All
        },
        clearActiveThroughputTableFilter: () => {
            return initialState
        }
    }
})

export const { setActiveThroughputTableFilter, clearActiveThroughputTableFilter } =
    throughputTableFilterSlice.actions

export default throughputTableFilterSlice
