import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface VarianceReportState {
    id: string
    title: string
    totalWaste: string
    avgYield: string
    totalYield: string
}

const initialState: VarianceReportState = {
    id: "",
    title: "",
    totalWaste: "",
    avgYield: "",
    totalYield: ""
}

const varianceReportSlice = createSlice({
    name: "active-variance-report",
    initialState,
    reducers: {
        setActiveVarianceReport: (state, action: PayloadAction<VarianceReportState>) => {
            state.title = action.payload.title ?? ""
            state.totalWaste = action.payload.totalWaste ?? ""
            state.avgYield = action.payload.avgYield ?? ""
            state.id = action.payload.id ?? ""
            state.totalYield = action.payload.totalYield ?? ""
        },
        clearActiveVarianceReport: () => {
            return initialState
        }
    }
})

export const { setActiveVarianceReport, clearActiveVarianceReport } = varianceReportSlice.actions

export default varianceReportSlice
