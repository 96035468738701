import {
    BrewMenu,
    InputCreateMenuItem,
    InputDeleteBmMenuItems,
    InputMenuItemOrSection,
    InputUpdateMenuItem,
    MenuItem,
    MenuSection,
} from "types"
import {
    useCreateMenuItemMutation,
    useCreateMenuSectionMutation,
    useDeleteMenuItemsMutation,
    useDeleteMenuItemsOrSectionsMutation,
    useRemoveMenuItemOrSectionFromBrewMenuMutation,
    useUpdateMenuItemMutation,
    useUpdateMenuSectionMutation,
} from "../../../api/brewMenu/brewMenuMenuItemAndSectionApi"

export const useMenuItemsAndSectionMutations = (bmSubId: string) => {
    const [createMenuItemMutation] = useCreateMenuItemMutation()
    const [createMenuSectionMutation] = useCreateMenuSectionMutation()
    const [deleteMenuItemsOrSectionsMutation] =
        useDeleteMenuItemsOrSectionsMutation()
    const [deleteMenuItemsMutation] = useDeleteMenuItemsMutation()
    const [removeMenuItemOrSectionFromBrewMenuMutation] =
        useRemoveMenuItemOrSectionFromBrewMenuMutation()
    const [updateMenuItemMutation] = useUpdateMenuItemMutation()
    const [updateMenuSectionMutation] = useUpdateMenuSectionMutation()

    const createMenuItem = async (
        item: Omit<InputCreateMenuItem, "bmSubId" | "parentId">,
        parentId: InputCreateMenuItem["parentId"] | undefined,
    ) => {
        if (bmSubId) {
            // @ts-ignore
            const { data } = await createMenuItemMutation({
                ...item,
                bmSubId,
                parentId: parentId || bmSubId,
            })

            if (data) return data
        }
    }

    const createMenuSection = async (
        menuId: BrewMenu["id"],
        title: string,
    ): Promise<{ id: BrewMenu["id"]; title: BrewMenu["title"] }> => {
        if (bmSubId) {
            // @ts-ignore
            const { data } = await createMenuSectionMutation({
                bmSubId,
                menuId,
                title,
            })

            if (data) return data
        }
    }

    const deleteMenuItemsOrSections = async (
        menuItemsOrSections: InputMenuItemOrSection[],
    ) => {
        if (bmSubId) {
            await deleteMenuItemsOrSectionsMutation({
                bmSubId,
                menuItemsOrSections,
            })
        }
    }

    const deleteMenuItems = async (
        menuItemIds: InputDeleteBmMenuItems["menuItemIds"],
    ) => {
        if (bmSubId) {
            await deleteMenuItemsMutation({
                bmSubId,
                menuItemIds,
            })
        }
    }

    const removeItemFromBrewMenu = async (
        brewMenuId: BrewMenu["id"],
        menuItemOrSection: InputMenuItemOrSection,
    ) => {
        if (bmSubId) {
            await removeMenuItemOrSectionFromBrewMenuMutation({
                bmSubId,
                brewMenuId,
                menuItemOrSection,
            })
        }
    }

    const updateMenuItem = async (
        menuItem: Omit<InputUpdateMenuItem, "bmSubId">,
    ) => {
        if (bmSubId) {
            const data = await updateMenuItemMutation({
                bmSubId,
                ...menuItem,
            })
            if (data) return data
        }
    }

    const updateMenuSection = async (
        menuSectionId: MenuSection["id"],
        menuItemIds: MenuItem["id"][],
        title: MenuSection["title"],
    ) => {
        if (bmSubId) {
            await updateMenuSectionMutation({
                bmSubId,
                menuSectionId,
                menuItemIds,
                title,
            })
        }
    }

    const updateHiddenSectionsOnMenuSection = async (
        menuSectionId: MenuSection["id"],
        menuItemIds: MenuItem["id"][],
        title: MenuSection["title"],
        hiddenOnDisplays?: string[],
    ) => {
        if (bmSubId) {
            await updateMenuSectionMutation({
                bmSubId,
                menuSectionId,
                menuItemIds,
                title,
                hiddenOnDisplays,
            })
        }
    }

    return {
        createMenuItem,
        createMenuSection,
        deleteMenuItemsOrSections,
        deleteMenuItems,
        removeItemFromBrewMenu,
        updateMenuItem,
        updateMenuSection,
        updateHiddenSectionsOnMenuSection,
    }
}
