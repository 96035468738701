"use client"
import React from "react"
import { Keg } from "types"
import { useGetUnassignedKegsQuery } from "../../../api/inventoryApi"
import { selectActivePpSubId } from "../../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../../store"

export interface UseUnassignedKegsHook {
    isFetching: boolean
    isLoading: boolean
    isSuccessUnassignedKegs: boolean
    isError: boolean
    refetchUnassignedKegs: () => void
    unassignedKegs: Keg[]
}

export const useUnassignedKegs = (): UseUnassignedKegsHook => {
    const ppSubId = useAppSelector(selectActivePpSubId)

    const {
        data: unassignedKegs,
        isLoading,
        isError,
        isFetching,
        isSuccess,
        refetch: refetchUnassignedKegs
    } = useGetUnassignedKegsQuery({ ppSubId })

    React.useEffect(() => {
        if (!unassignedKegs) {
            refetchUnassignedKegs()
        }
    }, [])

    return {
        refetchUnassignedKegs,
        unassignedKegs,
        isLoading,
        isSuccessUnassignedKegs: isSuccess,
        isError,
        isFetching
    }
}
