export const deleteOrganizationGroupMutation = `
mutation DeleteOrganizationGroup($input: inputDeleteOrganizationGroup!) {
	deleteOrganizationGroup(input: $input){
		id
		slug
		title
		bmSubId
		brandId
		cloudinaryAssetId
		ppSubId
	}
}`