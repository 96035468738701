import { skipToken } from "@reduxjs/toolkit/query"
import { useGetBrewMenuDisplaysByBMSubIdQuery } from "../../../api/brewMenu/brewMenuDisplayApi"
import { brewMenuSubscriptionToken } from "../utilities/brewMenuSubscriptionToken"

export const useBrewMenuDisplays = (bmSubId: string) => {
    const { data, isError, isFetching, isLoading, isSuccess } =
        useGetBrewMenuDisplaysByBMSubIdQuery(brewMenuSubscriptionToken(bmSubId) ?? skipToken)

    return {
        brewMenuDisplays: (data && data.items) || [],
        isErrorBrewMenuDisplays: isError,
        isFetchingBrewMenuDisplays: isFetching,
        isLoadingBrewMenuDisplays: isLoading,
        isSuccessBrewMenuDisplays: isSuccess,
    }
}
