"use client"

import React from "react"
import { User } from "types"

import { useGetBLXUserQuery } from "../../api"
import { useDatadog } from "../../datadog/useDatadog"

export interface UseBrewlogixUserHook {
    userData: User
    isFetchingUserData: boolean
    isUserDataError: boolean
    userDataError: any
    refetchUserData: () => void
    isUserDataSuccess: boolean
    isUserDataLoading: boolean
}

/**
 * Custom hook that replaces the `useBlxUser` hook.
 *
 * This hook provides user information specific to the BrewLogix User.
 * It fetches user data from BrewLogix using the RTK Query instance of `getAuthenticatedUser`
 * and provides various states related to the fetching process.
 *
 * @returns {useBrewlogixUserHook} An object containing user data and states.
 *
 * @property {any} userData - The user data fetched from BrewLogix.
 * @property {boolean} isFetchingUserData - Indicates if the user data is being fetched.
 * @property {boolean} isUserDataError - Indicates if there was an error fetching the user data.
 * @property {any} userDataError - The error object if there was an error fetching the user data.
 * @property {Function} refetchUserData - Function to refetch the user data.
 * @property {boolean} isUserDataLoading - Indicates if the user data is currently loading.
 * @property {boolean} isUserDataSuccess - Indicates if the user data was successfully fetched.
 */
export const useBrewlogixUser = (): UseBrewlogixUserHook => {
    const { setUser } = useDatadog()

    const {
        data: userData,
        isError: isUserDataError,
        isFetching: isFetchingUserData,
        isLoading: isUserDataLoading,
        isSuccess: isUserDataSuccess,
        error: userDataError,
        refetch: refetchUserData
    } = useGetBLXUserQuery()

    React.useCallback(() => {
        if (userData) setUser(userData)
    }, [userData])

    return {
        userData,
        isFetchingUserData,
        isUserDataError,
        userDataError,
        refetchUserData,
        isUserDataLoading,
        isUserDataSuccess
    }
}
