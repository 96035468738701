import { skipToken } from "@reduxjs/toolkit/query"
import { useGetBrewMenusByBMSubIdQuery } from "../../../api/brewMenu/brewMenuMenuApi"
import { brewMenuSubscriptionToken } from "../utilities/brewMenuSubscriptionToken"

export const useBrewMenuMenuList = (bmSubId: string) => {
    const { data, isError, isFetching, isLoading, isSuccess, refetch } =
        useGetBrewMenusByBMSubIdQuery(
            brewMenuSubscriptionToken(bmSubId) ?? skipToken,
        )

    return {
        menuList: data?.items || [],
        getAllBMMenuRefetch: refetch,
        isErrorBrewMenuMenuList: isError,
        isFetchingBrewMenuMenuList: isFetching,
        isLoadingBrewMenuMenuList: isLoading,
        isSuccessBrewMenuMenuList: isSuccess,
    }
}
